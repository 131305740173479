/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, TitleMain, Subtitle, Image, Button, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="--center pb--60 pt--60" anim={null} name={"intro"} animS={null} style={{"minHeight":"100vh"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 el--1 pl--20 pr--20 flex--center" anim={"2"} animS={"5"} columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <TitleMain className="title-box fs--128 w--300 title-box--invert swpf--uppercase ls--12 lh--12" style={{"maxWidth":""}} content={"<br><br>"}>
              </TitleMain>

              <Subtitle className="subtitle-box swpf--uppercase ls--50 lh--16 mt--30" content={" <br>  2021\n"}>
              </Subtitle>

              <Image className="--center mt--40" alt={""} src={"https://cdn.swbpg.com/o/11212/ccf91cd61cae410b904fc91de66f6618.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":64}} srcSet={""} content={null}>
              </Image>

              <Subtitle className="subtitle-box swpf--uppercase ls--50 lh--16 mt--40" content={""}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--3 flex--top" anim={null} animS={null} columns={"3"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --center" anim={"5"} animS={"5"}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/11212/73d8bf1957424732bcb01231356a1f8b_s=860x_.jpg"} svg={false} ratio={"3:4"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/73d8bf1957424732bcb01231356a1f8b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/73d8bf1957424732bcb01231356a1f8b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/73d8bf1957424732bcb01231356a1f8b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/73d8bf1957424732bcb01231356a1f8b_s=1400x_.jpg 1400w"} content={null} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center mt--80" anim={"2"} animS={"5"}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/11212/a3a3e498b6354fe29885983d37c2fd31_s=860x_.jpg"} svg={false} ratio={"3:4"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/a3a3e498b6354fe29885983d37c2fd31_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/a3a3e498b6354fe29885983d37c2fd31_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/a3a3e498b6354fe29885983d37c2fd31_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/a3a3e498b6354fe29885983d37c2fd31_s=1400x_.jpg 1400w"} content={null} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s5 --center" anim={"4"} animS={"5"}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/11212/a58c6b1fafbd41ddb0a5bf3752072bb7_s=860x_.jpg"} svg={false} ratio={"3:4"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/a58c6b1fafbd41ddb0a5bf3752072bb7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/a58c6b1fafbd41ddb0a5bf3752072bb7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/a58c6b1fafbd41ddb0a5bf3752072bb7_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/a58c6b1fafbd41ddb0a5bf3752072bb7_s=1400x_.jpg 1400w"} content={null} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--1 mt--60 pl--20 pr--20 flex--center" anim={null} animS={null} columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 swpf--uppercase" innerClassName="pb--06" href={"/hr/"} content={""}>
              </Button>

              <Text className="text-box text-box--invert" content={"Created with <a href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}